import { HttpClient } from '@angular/common/http';
import { inject, Injectable, isDevMode } from '@angular/core';
import StackTrace from 'stacktrace-js';

import { PlatformService } from '@ideals/core/platform';

import { DetailedProject } from '../../models';
import { DataTransporterService } from '../data-transporter';

interface LogEntry {
  readonly Application: 'app-frontend';
  readonly Browser: string;
  readonly ClientType: string;
  readonly Exception: string;
  readonly HostId: string;
  readonly Level: 'error' | 'debug' | 'warn' | 'info';
  readonly Message: string;
  readonly Os: string;
  readonly RoomId: string;
  readonly RoomName: string;
  readonly Url: string;
}

interface LogData {
  readonly exception?: string;
  readonly message: string;
}

@Injectable({ providedIn: 'root' })
export class LoggerService {
  readonly #dataTransporterService = inject(DataTransporterService);
  readonly #httpClient = inject(HttpClient);
  readonly #platformService = inject(PlatformService);

  debug(data: LogData): void {
    this.#sendLog(this.#generateLogEntry(data, 'debug'));
  }

  error(data: LogData | Error): void {
    if (data instanceof Error) {
      StackTrace.fromError(data)
        .then((frames) => {
          const exception = frames.map((frame) => frame.toString())[0] ?? '';

          this.#sendLog(this.#generateLogEntry({ exception, message: data.message }));
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      this.#sendLog(this.#generateLogEntry(data));
    }
  }

  info(data: LogData): void {
    this.#sendLog(this.#generateLogEntry(data, 'info'));
  }

  warn(data: LogData): void {
    this.#sendLog(this.#generateLogEntry(data, 'warn'));
  }

  #generateLogEntry(data: { exception?: string; message: string; }, level: LogEntry['Level'] = 'error'): LogEntry {
    const logData = { Exception: data.exception, Message: data.message, Level: level };

    return {
      ...logData,
      Application: 'app-frontend',
      Browser: `${this.#platformService.deviceInfo.browser} ${this.#platformService.deviceInfo.browser_version}`,
      ClientType: this.#getClientType(),
      HostId: this.#getProject()?.hostId ?? '',
      Os: `${this.#platformService.deviceInfo.os} ${this.#platformService.deviceInfo.os_version}`,
      RoomId: this.#getProject()?.id ?? '',
      RoomName: this.#getProject()?.name ?? '',
      Url: window.location.href,
    } as LogEntry;
  }

  #getClientType(): string {
    if (this.#platformService.platformType() === 'mobile') {
      return this.#platformService.isStandalone() ? 'mobile app' : 'mobile';
    }

    return this.#platformService.platformType();
  }

  #getProject(): DetailedProject | undefined {
    return this.#dataTransporterService.getProject();
  }

  #sendLog(logEntry: LogEntry): void {
    if (isDevMode()) {
      return;
    }

    this.#httpClient.post('/api/v1/log', logEntry).subscribe({
      next: () => {},
      error: (e) => console.error(e),
    });
  }
}
